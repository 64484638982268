import axios from 'axios';

function getReport(params) {
    return request('get', '/api/reporting/report', params);
}

function getReports(account) {
    return request('get', '/api/reporting/reports', {account});
}

function getUsers(vertical) {
    return request('get', '/api/reporting/users', {vertical});
}

function getAccounts() {
    return request('get', '/api/reporting/accounts');
}

async function request(method, url, params = {}) {
    const res = await axios.request({
        method,
        url,
        params,
    });

    return res.data;
}

export {
    getReport,
    getReports,
    getAccounts,
    getUsers
};
