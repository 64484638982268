import React from 'react';
import * as _ from 'lodash';
import { id } from '../common/utils';

export default function ReportTableHead({data, setSort}) {
    return (
        <thead>
        <tr className="info">
            <th onClick={() => setSort('keyGroup.value')}>
                {data[0].keyGroup.name}</th>
            {_.map(data[0].fields, field =>
                [
                    <th onClick={() => setSort(`fields.${field.key}.current`)}
                        key={id()}>{field.fieldLabel} (C)</th>,
                    <th onClick={() => setSort(`fields.${field.key}.previous`)}
                        key={id()}>{field.fieldLabel} (P)</th>,
                    <th onClick={() => setSort(`fields.${field.key}.diff`)}
                        key={id()}>{field.fieldLabel} (D)</th>
                ]
            )}
        </tr>
        </thead>
    )
}