import React, {Component} from 'react'
import {Header, Report, Toolbar, Loader} from '../components';
import SessionStore from '../sessionStore'
import {getAccounts, getReports, getUsers} from '../api/reportingAPI';
import './App.scss';

export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reports: [],
            accounts: [],
            selectedAccount: '',
            selectedTimespan: 'today',
            isLoading: true,
        };

        this.sessionStore = new SessionStore({onLogin: this.onLogin.bind(this)});
    }

    onLogin() {
        this.getAccounts();
    }

    async getAccounts() {
        const accounts = await getAccounts();
        this.setState({
            accounts,
            isLoading: false,
        });
        this.setAccount();
    }

    moveToAccount = async (accountId, filterBy) => {
        await this.setAccount(accountId);
        const user = this.state.usersByVertical.find(user => (user.label === filterBy));
        this.onSelecteUser(user);
    }

    setAccount = async (accountId) => {
        if (!accountId) {
            accountId = localStorage.getItem('accountId');
        }

        const account = accountId ? this.state.accounts.find(a => a._id === accountId) : this.state.accounts[0];

        localStorage.setItem('accountId', account._id);

        this.setState({reports: []})

        const selectedTimespan = localStorage.getItem("selectedTimespan");
        if (selectedTimespan) {
            this.setState({selectedTimespan});
        }

        const selectedUser = localStorage.getItem('selectedUser');
        if (selectedUser) {
            this.setState({selectedUser: JSON.parse(selectedUser)});
        }


        const promises = [getReports(account._id)]
        if (account.mustUser && account.verticalType) {
            promises.push(getUsers(account.verticalType))
        }

        const [reports, usersByVertical] = await Promise.all(promises);

        this.setState({selectedAccount: account, reports, usersByVertical});
    }

    onSelecteUser = (value) => {
        this.setState({selectedUser: value})
        localStorage.setItem('selectedUser', JSON.stringify(value));
    }

    setTimespan = (timespan) => {
        this.setState({selectedTimespan: timespan});
        localStorage.setItem('selectedTimespan', timespan);
    };

    render() {
        return this.state.isLoading ? <Loader/> : this.renderContent();
    }

    jumpToReport = (report) => {
        const el = document.getElementById(report);
        window.scrollTo(0, el.offsetTop);
    }

    goToNew = () => {
        window.open("https://bi-dashboards-2.vidazoo.com/")
    }

    renderContent() {
        const {selectedTimespan, selectedAccount, accounts, reports, usersByVertical, selectedUser} = this.state;

        return (
            <div className="app-container">
                <Toolbar selectedTimespan={selectedTimespan}
                         setTimespan={this.setTimespan}
                         accounts={accounts}
                         usersByVertical={usersByVertical}
                         selectedUser={selectedUser}
                         selecteUser={this.onSelecteUser}
                         selectedAccount={selectedAccount}
                         reports={reports}
                         jumpToReport={this.jumpToReport}
                         setAccount={this.setAccount}/>

                {reports.map(report => <Report selectedUser={selectedUser} key={report._id} label={report.label}
                                               id={report._id} timespan={this.state.selectedTimespan}
                                               moveToAccount={this.moveToAccount}
                                               sortKey={report.sortKey}
                                               sortOrder={report.sortOrder}
                                               bridgeGroupReports={report.bridgeGroupReports}/>)}

                <div className="go-to-new" onClick={this.goToNew}>Move to the New Bi-Dashboard!</div>
            </div>
        );
    }
}
