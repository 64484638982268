import React, {Component} from 'react';
import {ReportTable, Loader} from '../components';
import {getReport} from '../api/reportingAPI';
import './Report.scss';
import {downloadReportAsCSV} from '../common/utils';

export default class Report extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.loadReport();
    }

    componentDidUpdate(prevProps) {
        if (this.props.timespan !== prevProps.timespan || this.props.selectedUser !== prevProps.selectedUser) {
            this.loadReport();
        }
    }

    download = () => {
        const {label, timespan} = this.props;
        const {data} = this.state;

        if (data.length) {
            downloadReportAsCSV({data, label, timespan});
        }
    };

    async loadReport() {
        this.setState({isLoading: true});
        const params = {id: this.props.id, timespan: this.props.timespan}
        if (this.props.selectedUser && this.props.selectedUser.value) {
            params.user = this.props.selectedUser.value;
        }
        const report = await getReport(params);
        this.setState({isLoading: false, data: report.data});
    }

    render() {
        const {data, filterBy} = this.state;
        const {label, bridgeGroupReports, moveToAccount} = this.props;

        return (
            <div>
                <div id={label} className="report-header">
                    <h1>{label}</h1>
                    <button className="btn btn-outline-dark" onClick={this.download}>
                        <i className="fa fa-cloud-download"/>
                    </button>
                    {filterBy ? (
                        <div className="filter">
                            <h4>{filterBy}</h4>
                            <i onClick={() => this.onFilterBy(null)} className="fa fa-times-circle"/>
                        </div>
                    ) : null}
                </div>
                {this.getContent()}
            </div>
        );
    }

    onFilterBy = (value) => {
        if (value === this.state.filterBy) {
            this.setState({filterBy: null})
        } else {
            const {bridgeGroupReports, moveToAccount} = this.props;
            this.setState({filterBy: value})

            if (bridgeGroupReports) {
                moveToAccount(bridgeGroupReports, value)
            }
        }
    }

    getContent() {
        const {sortKey, sortOrder} = this.props;
        const filterdData = this.state.filterBy ? this.state.data.filter(row => row.keyGroup.value === this.state.filterBy) : this.state.data;

        if (this.state.isLoading) {
            return <Loader/>;
        } else if (!this.state.data.length) {
            return <NoData/>;
        } else {
            return <ReportTable data={filterdData} stickyColumns={1} filterBy={this.onFilterBy} sortKey={sortKey} sortOrder={sortOrder}/>
        }
    }

}

function NoData() {
    return (
        <div style={{textAlign: 'center'}}>No data</div>
    )
}
