import React from 'react';
import { id } from '../common/utils';

const decimalPlaces = {
    fillRate: 2,
    eligibleRequestFillRateConnections: 2,
    sps: 6,
    rpmUnique: 2,
    playerLoadFillRate: 2,
    rpm: 2,
    rpmPlayerRendered: 2
};

export default function RegularCell({field, value}) {
    const places = decimalPlaces[field] || 0;
    return (
        <td key={id()}
            data-tip="hello world">{value ? value.toLocaleString(undefined, {maximumFractionDigits: places}) : '-'}</td>
    )
}
