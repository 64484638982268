import React from 'react';
import classnames from 'classnames';
import { id } from '../common/utils';
import './ReportDiffCell.scss';

export default function DiffCell({value}) {
    let cellValue = value ? `${value > 0 ? '+' : ''}${Math.round(value)}%` : '-';
    let cellClass = classnames({
        green: value > 30,
        red: value < -30,
        diff: true
    });
    return (
        <td className={cellClass} key={id()}>{cellValue}</td>
    );
}