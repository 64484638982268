export default class SessionStore {

    constructor({onLogin}) {
        this.createSDK();
        this.initSession();
        this.onLogin = onLogin;
    }

    createSDK() {
        this.sdk = new VidazooSDK({
            scope: "bi_dashboard",
            interceptUrls: [/./]
        });
    }

    onSessionChange(err, res) {
        this.onLogin();
    }

    initSession() {
        this.sdk.auth.on("session:change", (err, res) => this.onSessionChange(err, res));

        this.sdk.auth.authorize();
    }
}