import React from 'react';
import './Toolbar.scss';
import * as classnames from 'classnames';
import Select from 'react-select';

const TIMESPANS = ['2_hours', '4_hours', 'today', 'yesterday'];

export default function Toolbar({selectedTimespan, setTimespan, accounts, selectedAccount, setAccount, timespans, usersByVertical, selecteUser, selectedUser, reports, jumpToReport}) {
    return (
        <header>
            <nav>
                <form className="container">
                    <div className="btn-reports">
                        {TIMESPANS.map(timespan =>
                            <button key={timespan} type="button"
                                    onClick={() => setTimespan(timespan)}
                                    className={classnames("btn-tool", {active: selectedTimespan === timespan})}>{timespan.toUpperCase()}</button>)}
                    </div>

                    <div className="btn-reports">
                        {accounts.map(account =>
                            <button key={account._id} type="button"
                                    onClick={() => setAccount(account._id)}
                                    className={classnames("btn-tool", {active: selectedAccount._id === account._id})}>{account._id.toUpperCase()}</button>)}
                    </div>

                    <div className="btn-reports">
                        {reports.map((report, idx) =>
                            <button key={idx} type="button"
                                    onClick={() => jumpToReport(report.label)}
                                    className="btn-tool">{report.label}</button>)}
                    </div>
                </form>

                <div className="details-tools">
                    <span>(C) = Current timespan | (P) = Previous timespan (previous 7 days) | (D) = Difference in
                        percentage between (C) & (P)
                    </span>
                    {usersByVertical ? (
                        <div className="select">
                            <Select
                                value={selectedUser}
                                onChange={selecteUser}
                                options={usersByVertical}
                            />
                        </div>
                    ) : null}
                </div>
            </nav>
        </header>
    );
}
