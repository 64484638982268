export function id(length) {
    return Math.floor(Date.now() * Math.random()).toString(16).slice(length - 1);
}

export function downloadReportAsCSV({data, label, timespan}) {
    if (!data || !data.length) {
        return;
    }

    const csv = generateCSV(data);

    const date = new Date();
    const filename = `${label.replace(/\s/g, '_')}_${date.toISOString()}_${timespan}.csv`;

    download(csv, 'text/csv;charset=utf-8;', filename);
}

function generateCSV(data) {
    const headerRow = Object.keys(data[0].fields).reduce((arr, field) => {
        return [...arr, ...(['(C)', '(P)', '(D)'].map(type => `${field} ${type}`))];
    }, [data[0].keyGroup.name]);

    const bodyRows = data.map(row => {
        return Object.keys(row.fields).reduce((arr, field) => {
            return [...arr, ...(['current', 'previous', 'diff'].map(type => row.fields[field][type]))];
        }, [row.keyGroup.value]);
    });

    return [headerRow].concat(bodyRows).map(row => row.join(',')).join('\n');
}

function download(content, type, filename) {
    const blob = new Blob([content], {type});
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}