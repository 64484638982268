import React from 'react';
import * as _ from 'lodash';
import { id } from '../common/utils';
import {ReportDiffCell, ReportRegularCell} from '../components';
import './ReportTableBody.scss';

export default function ReportTableBody({data, filterBy}) {
    return (
        <tbody>
        {
            _.map(data, (row) => {
                return (
                    <tr key={id()}>
                        <td className="keygroup" onClick={() => filterBy(row.keyGroup.value)}>{row.keyGroup.value}</td>
                        {
                            Object.keys(row.fields).map(field =>
                                [
                                    <ReportRegularCell key={id()} field={field} value={row.fields[field].current}/>,
                                    <ReportRegularCell key={id()} field={field} value={row.fields[field].previous}/>,
                                    <ReportDiffCell key={id()} value={row.fields[field].diff}/>
                                ]
                            )
                        }
                    </tr>
                )
            })
        }
        </tbody>
    );
}
