import React, {Component} from 'react';
import * as _ from 'lodash';
import {ReportTableHead, ReportTableBody} from '../components';
import './ReportTable.scss';

export default class ReportTable extends Component {

    constructor(props) {
        super(props);

        this.$table = null;

        const sortField = props.sortKey ? props.sortKey : 'keyGroup';

        const sortOrder = props.sortOrder ? props.sortOrder : 'asc';

        this.state = {
            sortField,
            sortOrder,
        };
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onWindowScroll, {passive: true});
        window.removeEventListener("resize", this.onWindowResize);
    }

    componentDidMount() {
        this.setSticky();

        window.addEventListener("scroll", this.onWindowScroll, {passive: true});
        window.addEventListener("resize", this.onWindowResize);
    }

    onWindowScroll = () => {
        this.setSticky();
    };

    onWindowResize = () => {
        this.setSticky();
    };

    setSticky() {
        const {stickyColumns} = this.props;
        if (!stickyColumns) {
            return;
        }

        window.requestAnimationFrame(() => {
            if (!this.$table) {
                return;
            }

            const tableRect = this.$table.getBoundingClientRect();
            const leftScroll = Math.abs(tableRect.left);

            const cells = this.$table.querySelectorAll("th:first-child, td:first-child");

            cells.forEach((el) => el.style.transform = `translateX(${leftScroll}px)`);

            // if (tableRect.top < stickyHeaderOffsetTop) {
            //
            //     const absTop = Math.abs(tableRect.top);
            //
            //     let translateY;
            //
            //     if (tableRect.top + tableRect.height - stickyHeaderOffsetTop <= theadRect.height) {
            //         translateY = tableRect.height - theadRect.height;
            //     } else {
            //         translateY = tableRect.top > 0
            //             ? stickyHeaderOffsetTop - tableRect.top
            //             : stickyHeaderOffsetTop + absTop;
            //     }
            //
            //     this.stickyHeader = true;
            //     thead.style.transform = `translateY(${translateY}px)`;
            // } else {
            //     this.stickyHeader = false;
            //     thead.style.transform = "";
            // }
        });
    }

    setRef = (el) => {
        this.$table = el;
    }

    get sortedData() {
        return sortReport(this.props.data, this.state.sortField, this.state.sortOrder);
    }

    setSort = (sortField) => {
        let sortOrder = 'desc';

        if (this.state.sortField === sortField) {
            sortOrder = (this.state.sortOrder === 'desc') ? 'asc' : 'desc';
        }

        this.setState({
            sortField,
            sortOrder,
        });
    };

    render() {
        return (
            <table ref={this.setRef}
                   className="table table-condensed table-bordered table-hover report st_table_header">
                <ReportTableHead data={this.props.data} setSort={this.setSort}/>
                <ReportTableBody data={this.sortedData} filterBy={this.props.filterBy}/>
            </table>
        );
    }

}

function sortReport(data, sortField, sortOrder) {
    return _.orderBy(data, [function (row) {

        const value = _.get(row, sortField);

        return value ? value : -Infinity;

    }], [sortOrder]);
}
